<template>
  <div>
    <public-header />
    <div class="wrapper">
      <div class="page-header page-header-small">
        <div
          class="page-header-image"
          data-parallax="true"
        />
        <div class="content-center mt-4">
          <div class="container">
            <h1 class="title h1">
              About us
            </h1>
          </div>
        </div>
      </div>
      <div class="container my-5">
        <div class="row">
          <div class="col-md-6">
            <div class="section-subtitle text-center mb-4">What is</div>
            <div class="section-title text-center mb-5">
              Let's Connect?
            </div>

            <div class="my-4">Founded in 2020, Let's Connect is a business communication platform based in San Dimas, California aiming to help small businesses effectively engage with their customers and partners. Our team is passionate about effective business communications, digital marketing, and client digital interfacing. We are dedicated to making it easy for small and medium-sized companies to get started with business voice, video, email, and texting infrastructure. </div>

            <div class="my-4">We understand that effectively reaching your audience can often be an enormous challenge, which is why we are dedicated to making business communication as smooth and seamless as possible.</div>

            <div class="my-4">Our marketing expertise and cutting-edge platform allow you to reach the right people at the right time. We produce, automate, and distribute compelling business communications in real time while you focus on more important tasks.</div>

            <div class="my-4">Our team has years of experience working with small businesses, which puts us in an advantageous position to help your business. We understand your unique challenges and are equipped to maximize your unique position. </div>

            <div class="my-4">Our experience has taught us that small businesses best help other small businesses; you are in good hands. We collaborate with some of the world's most prestigious corporations and we are ready to take you to the next level.</div>
          </div>

          <div class="col-md-6">
            <div class="section-subtitle text-center mb-4">Connect with us</div>
            <div class="section-title text-center mb-5">
              Contact us today to learn more.
            </div>

            <div class="featuresBig bg-white py-0">
              <div class="featureItem">
                <form
                  id="contact-form"
                  role="form"
                  method="post"
                  class="text-left"
                >
                  <label>Your name</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons users_circle-08" /></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Name..."
                      aria-label="Your Name..."
                      autocomplete="name"
                    >
                  </div>
                  <label>Email address</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons ui-1_email-85" /></span>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email Here..."
                      aria-label="Email Here..."
                      autocomplete="email"
                    >
                  </div>
                  <label>Phone</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons tech_mobile" /></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Number Here..."
                      autocomplete="number"
                    >
                  </div>
                  <div class="form-group">
                    <label>Your message</label>
                    <textarea
                      id="message"
                      name="message"
                      class="form-control"
                      rows="6"
                    />
                  </div>
                  <div class="submit text-center">
                    <input
                      type="submit"
                      class="btn btn-primary btn-raised btn-round"
                      value="Contact Us"
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div>
          <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div>
          <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div>
          <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div>
          <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div>
          <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div>
          <div class="col-md-3 mx-auto">
            <div class="teamMember">
              <img
                src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                alt="alt"
              >
              <p class="name">
                Jon Doe
              </p>
              <p class="desig">
                Managing Director
              </p>
              <p class="summary">
                Jon founded ClickSend and has successfully grown the business globally.
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <public-footer />
  </div>
</template>

<script>
  export default {
    name: 'AboutUsPage',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
    }),
    computed: {},
    created () {
      window.scrollTo(0, 0)
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';
.page-header-image {
  // background-image: radial-gradient(circle 1693px at 100% 100%, var(--clr-secondary) 0%, var(--clr-secondary) 33%, var(--clr-accent-dark) 66%);
  background-color: var(--clr-accent);
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  // height: 300px!important;
  position: relative;
}
.getStartedButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .getStarted {
    margin-top: 15px;
    background-color: rgb(233, 178, 0);
    color: rgb(255, 255, 255);
    padding: 16px 52px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 6px;
  }
}
.serviceCardOuterWrapper {
  padding: 15px 36px;
  .serviceCardInnerWrapper {
    height: 400px;
    box-shadow: 0px 0px 100px rgba(0,0,0,0.15);
    border-radius: 6px;
    background-color: white;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .serviceCardIconImg {
      width: 130px;
      height: 130px;
    }
    .cardHeader {
      margin-top: 35px;
      font-size: 1.3rem;
    }
    .smsCardSubHeader {
      text-align: center;
      font-size: 0.9rem;
      color: grey;
    }
    .cardActionBtn {
      margin-top: 35px;
      cursor: pointer;
      padding: 6px 30px;
      font-size: 0.9rem;
      border: 1px solid rgb(0, 128, 255);
      border-radius: 6px;
      box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
    }
    &.smsService {
      .cardActionBtn {
        border: 1px solid #61BCF5;
        &:hover {
          background-color: #61BCF5;
          color: white;
        }
      }
    }
    &.mmsService {
      .cardActionBtn {
        border: 1px solid #420FB7;
        &:hover {
          background-color: #420FB7;
          color: white;
        }
      }
    }
    &.rmService {
      .cardActionBtn {
        border: 1px solid #FF6A4B;
        &:hover {
          background-color: #FF6A4B;
          color: white;
        }
      }
    }
    &.voiceService {
      .cardActionBtn {
        border: 1px solid #FC6DE5;
        &:hover {
          background-color: #FC6DE5;
          color: white;
        }
      }
    }
  }
}
.pricingWrapper {
  // background-color: rgb(233, 178, 0);
  background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-abstract-line-background-design-image_351709.jpg');
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100vw;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .pricingIconWrapper {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: white;
    border-radius: 60px;
    color: rgb(233, 178, 0)!important;
    * {
      color: rgb(233, 178, 0)!important;
    }
    font-size: 2.3rem;
  }
  .pricingHeader {
    font-size: 2rem;
    margin-top: 10px;
    color: white;
  }
  .viewPlansBtn {
    margin-top: 35px;
    cursor: pointer;
    padding: 10px 40px;
    font-size: 0.9rem;
    color: white;
    background-color: rgb(219, 186, 0);
    border-radius: 6px;
    box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
  }
}
.teamMember {
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  cursor: pointer;
  img {
    border-radius: 200px;
    width: 180px;
    filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  &:hover {
    filter: unset; /* IE6-9 */
  -webkit-filter: unset; /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: unset; /* Microsoft Edge and Firefox 35+ */
  }
  }
  .name {
    margin-top: 1.9rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .desig {
    font-size: 1rem;
  }
  .summary {
    text-align: center;
    font-size: 0.9rem;

  }
}
.letsconnectTeam {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  // margin-bottom: -2rem;
  margin-top: 4rem;
}
</style>
